angular.module('app.controllers').controller 'RegistrationCtrl', [
  '$scope', '$rootScope', 'APIVisitor', '$timeout', '$location', 'APIConfirm', 'smoothScr', 'APICheckEmail', 'UI', 'APITickets', '$cookies', '$window'
].append (self, root, APIVisitor, $timeout, $location, APIConfirm, smoothScr, APICheckEmail, UI, APITickets, $cookies, $window) ->
  #  1 - init screen 
  #  2 - confirmed/login
  #  3 - fill data
  #  4 - ticket scan
  self.step     = 1 
  self.formErrors   = null
  self.formProccess = false
  self.formSuccess  = false

  self.passType  = true
  self.passType2 = true
  self.togglePass = (pass = true) ->
    return self.passType = !self.passType if pass
    self.passType2 = !self.passType2

  self.setStep = (step) ->
    self.step = step
    $timeout () -> smoothScr.scroll('#body-top', 0)

  (self.resetData = () ->
    self.data =
      gender: ""

      first_name: ""
      last_name: ""

      company: ""
      position: ""
      phone: ""
      email: ""
      password: ""
      
      source: ""
      ticket_category: 0

      locale: "DE"
      shortcode_supplementary: ""

      settings:
        additional_field:
          additional_field_label_1: "Ja, ich möchte am Gewinnspiel teilnehmen."
          additional_field_value_1: false

          additional_field_label_2: "Hiermit stimme ich den Datenschutzbestimmungen zu."
          additional_field_value_2: false

          additional_field_label_3: "Fast geschafft ..."
          additional_field_value_3: false


    self.fake =
      email: ""
      password: ""
      barcode: ""
      code: ""

  )()

  self.saveForm = (form) ->
    self.rForm = form

  self.saveMForm = (form, name = '') ->
    self[name] = form

  self.openScan = () ->
    root.$broadcast 'showScModal', 'setRegisterCode'

  self.setCode = (code = '') ->
    self.fake.code = code
    self.data.settings.additional_field.additional_field_value_4 = code

  self.$on 'setRegisterCode', (event, code) ->    
    self.setCode(code)
    root.scModal.hide()

  self.register = () ->
    self.formProccess = true

    reg_params = {}
    reg_ref = $cookies.get('reg_ref') || null
    reg_params.ref = reg_ref.split('--')[1] if reg_ref?

    (new APIVisitor self.data).$save reg_params, (data) ->

      self.done_email = angular.copy(self.data.email)

      self.formProccess = false
      self.formSuccess  = true    
      self.resetData()

      self.rForm1?.$setPristine()
      self.rForm1?.$setUntouched()
      self.rForm2?.$setPristine()
      self.rForm2?.$setUntouched()
      self.rForm3?.$setPristine()
      self.rForm3?.$setUntouched()

      $timeout () -> smoothScr.scroll('#regBlock', 0)
      
    , (response) ->
      self.formProccess = false
      self.formSuccess  = false

      UI.notify false, response.data.error


  self.checkEmail = () ->
    self.formProccess = true

    APICheckEmail.get {email: self.data.email}, () ->
      self.formProccess = false
      #self.setStep(2)
      self.register()
    , (response) ->
      self.formProccess = false

      self.data.email = ''
      self.rForm1.$setPristine()
      self.rForm1.$setUntouched()

      #if response.data.error == 'Ein Ticket mit dieser E-Mail-Adresse wurde bereits registriert'
      #  response.data.error = 'Diese E-Mailadresse wurde bereits verwendet. Wir haben das Bestätigungsemail erneut gesendet. Bitte klicken Sie auf den darin enthalteten Link, um die E-Mail Adresse zu bestätigen, und den Registrierungsprozess fortzusetzen. Falls Sie die E-mail nicht bekommen haben, sehen Sie auch im Spam Ordner nach.'
      
      UI.notify false, response.data.error



  # {Anrede} <br>
  # {Vorname} {Nachname} <br>
  # {Telefonnummer} <br>
  # {E-Mail}

  self.getTicketData = (step = 2) ->
    return self.data.email if step == 2

    data    = []
    fields  = ['gender', 'phone', 'email']
    name    = "#{self.data.first_name} #{self.data.last_name}"

    for i in fields
      field = null
      if i in ['additional_field_value_2', 'additional_field_value_3', 'additional_field_value_4', 'additional_field_value_5']
        field = self.data.settings.additional_field["#{i}"] if self.data.settings.additional_field["#{i}"]
      else
        field = self.data["#{i}"] if self.data["#{i}"]

      if i == "gender"
        switch field
          when "true"   then field = "Herr"
          when "false"  then field = "Frau"
          when "null"   then field = "keine Anrede"

      data.push field if field?
      data.push name if i == "gender"

    return data.join("<br>")



  ###
  $window.regByGoogle = (response) ->
    responsePayload = jwt_decode(response.credential)
    self.data.first_name = responsePayload.given_name
    self.data.last_name = responsePayload.family_name
    self.data.email = responsePayload.email
    self.data.settings.additional_field.additional_field_value_2 = true
    
    google.accounts.id.revoke(responsePayload.sub, (done) ->
      console.log "success revoke"
    ) if responsePayload.sub?

    self.$applyAsync()

    
  (onGoogleLibraryLoad = () ->
    google.accounts.id.initialize({
      client_id: '918412787533-h3fcib37nl3mioh9v73ukogoao6b10cu.apps.googleusercontent.com'
      callback: regByGoogle
      context: "use"
    })
    parent = document.getElementById('google_btn')
    google.accounts.id.renderButton(parent, {
      theme: "outline", 
      text: "continue_with", 
      logo_alignment: "center", 
      width: 400
    })
  )()
  ###



angular.module('app.controllers').controller 'ConfirmCtrl', [
  '$scope', '$timeout', '$location', 'APIConfirm', '$rootScope', 'UI', '$cookies', 'APIActivity'
].append (self, $timeout, $location, APIConfirm, root, UI, $cookies, APIActivity) ->

  self.formProccess  = false    
  self.formSuccess   = false

  params = $location.search()
  
  if params && angular.isDefined(params.confirm)   
    self.formProccess = true

    APIConfirm.get {hash: params.confirm}, (data) ->

      # sync offline activities
      reg_ref = $cookies.get('reg_ref') || null
      if reg_ref? && reg_ref.split('--')[1] == data.referred_supplier?.referrer_hash
        $cookies.remove('reg_ref')
        
        (new APIActivity).$save {shortcode: reg_ref, auth_ticket: data.auth_ticket, ref_id: data.referred_supplier.id}, (data) ->
          console.log "sync activity success" 
        , (response) ->
          console.log response.data.error

      self.formProccess = false
      self.formSuccess  = true
      
      ###
      usercookie =
        shortcode:    data.shortcode
        auth_ticket:  data.auth_ticket
        role:         data.role || null
        guest:        false
      $cookies.putObject 'user', usercookie

      root.$emit 'proveSession', true
      ###

    , (response) ->
      self.formProccess = false
      self.formSuccess  = false

      UI.notify false, response.data.error

  else
    $location.path("/")
