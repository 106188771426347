angular.module('app.controllers').controller('RegistrationCtrl', ['$scope', '$rootScope', 'APIVisitor', '$timeout', '$location', 'APIConfirm', 'smoothScr', 'APICheckEmail', 'UI', 'APITickets', '$cookies', '$window'].append(function(self, root, APIVisitor, $timeout, $location, APIConfirm, smoothScr, APICheckEmail, UI, APITickets, $cookies, $window) {
  //  1 - init screen 
  //  2 - confirmed/login
  //  3 - fill data
  //  4 - ticket scan
  self.step = 1;
  self.formErrors = null;
  self.formProccess = false;
  self.formSuccess = false;
  self.passType = true;
  self.passType2 = true;
  self.togglePass = function(pass = true) {
    if (pass) {
      return self.passType = !self.passType;
    }
    return self.passType2 = !self.passType2;
  };
  self.setStep = function(step) {
    self.step = step;
    return $timeout(function() {
      return smoothScr.scroll('#body-top', 0);
    });
  };
  (self.resetData = function() {
    self.data = {
      gender: "",
      first_name: "",
      last_name: "",
      company: "",
      position: "",
      phone: "",
      email: "",
      password: "",
      source: "",
      ticket_category: 0,
      locale: "DE",
      shortcode_supplementary: "",
      settings: {
        additional_field: {
          additional_field_label_1: "Ja, ich möchte am Gewinnspiel teilnehmen.",
          additional_field_value_1: false,
          additional_field_label_2: "Hiermit stimme ich den Datenschutzbestimmungen zu.",
          additional_field_value_2: false,
          additional_field_label_3: "Fast geschafft ...",
          additional_field_value_3: false
        }
      }
    };
    return self.fake = {
      email: "",
      password: "",
      barcode: "",
      code: ""
    };
  })();
  self.saveForm = function(form) {
    return self.rForm = form;
  };
  self.saveMForm = function(form, name = '') {
    return self[name] = form;
  };
  self.openScan = function() {
    return root.$broadcast('showScModal', 'setRegisterCode');
  };
  self.setCode = function(code = '') {
    self.fake.code = code;
    return self.data.settings.additional_field.additional_field_value_4 = code;
  };
  self.$on('setRegisterCode', function(event, code) {
    self.setCode(code);
    return root.scModal.hide();
  });
  self.register = function() {
    var reg_params, reg_ref;
    self.formProccess = true;
    reg_params = {};
    reg_ref = $cookies.get('reg_ref') || null;
    if (reg_ref != null) {
      reg_params.ref = reg_ref.split('--')[1];
    }
    return (new APIVisitor(self.data)).$save(reg_params, function(data) {
      var ref, ref1, ref2, ref3, ref4, ref5;
      self.done_email = angular.copy(self.data.email);
      self.formProccess = false;
      self.formSuccess = true;
      self.resetData();
      if ((ref = self.rForm1) != null) {
        ref.$setPristine();
      }
      if ((ref1 = self.rForm1) != null) {
        ref1.$setUntouched();
      }
      if ((ref2 = self.rForm2) != null) {
        ref2.$setPristine();
      }
      if ((ref3 = self.rForm2) != null) {
        ref3.$setUntouched();
      }
      if ((ref4 = self.rForm3) != null) {
        ref4.$setPristine();
      }
      if ((ref5 = self.rForm3) != null) {
        ref5.$setUntouched();
      }
      return $timeout(function() {
        return smoothScr.scroll('#regBlock', 0);
      });
    }, function(response) {
      self.formProccess = false;
      self.formSuccess = false;
      return UI.notify(false, response.data.error);
    });
  };
  self.checkEmail = function() {
    self.formProccess = true;
    return APICheckEmail.get({
      email: self.data.email
    }, function() {
      self.formProccess = false;
      //self.setStep(2)
      return self.register();
    }, function(response) {
      self.formProccess = false;
      self.data.email = '';
      self.rForm1.$setPristine();
      self.rForm1.$setUntouched();
      //if response.data.error == 'Ein Ticket mit dieser E-Mail-Adresse wurde bereits registriert'
      //  response.data.error = 'Diese E-Mailadresse wurde bereits verwendet. Wir haben das Bestätigungsemail erneut gesendet. Bitte klicken Sie auf den darin enthalteten Link, um die E-Mail Adresse zu bestätigen, und den Registrierungsprozess fortzusetzen. Falls Sie die E-mail nicht bekommen haben, sehen Sie auch im Spam Ordner nach.'
      return UI.notify(false, response.data.error);
    });
  };
  // {Anrede} <br>
  // {Vorname} {Nachname} <br>
  // {Telefonnummer} <br>
  // {E-Mail}
  return self.getTicketData = function(step = 2) {
    var data, field, fields, i, j, len, name;
    if (step === 2) {
      return self.data.email;
    }
    data = [];
    fields = ['gender', 'phone', 'email'];
    name = `${self.data.first_name} ${self.data.last_name}`;
    for (j = 0, len = fields.length; j < len; j++) {
      i = fields[j];
      field = null;
      if (i === 'additional_field_value_2' || i === 'additional_field_value_3' || i === 'additional_field_value_4' || i === 'additional_field_value_5') {
        if (self.data.settings.additional_field[`${i}`]) {
          field = self.data.settings.additional_field[`${i}`];
        }
      } else {
        if (self.data[`${i}`]) {
          field = self.data[`${i}`];
        }
      }
      if (i === "gender") {
        switch (field) {
          case "true":
            field = "Herr";
            break;
          case "false":
            field = "Frau";
            break;
          case "null":
            field = "keine Anrede";
        }
      }
      if (field != null) {
        data.push(field);
      }
      if (i === "gender") {
        data.push(name);
      }
    }
    return data.join("<br>");
  };
}));

/*
$window.regByGoogle = (response) ->
  responsePayload = jwt_decode(response.credential)
  self.data.first_name = responsePayload.given_name
  self.data.last_name = responsePayload.family_name
  self.data.email = responsePayload.email
  self.data.settings.additional_field.additional_field_value_2 = true

  google.accounts.id.revoke(responsePayload.sub, (done) ->
    console.log "success revoke"
  ) if responsePayload.sub?

  self.$applyAsync()

(onGoogleLibraryLoad = () ->
  google.accounts.id.initialize({
    client_id: '918412787533-h3fcib37nl3mioh9v73ukogoao6b10cu.apps.googleusercontent.com'
    callback: regByGoogle
    context: "use"
  })
  parent = document.getElementById('google_btn')
  google.accounts.id.renderButton(parent, {
    theme: "outline", 
    text: "continue_with", 
    logo_alignment: "center", 
    width: 400
  })
)()
*/
angular.module('app.controllers').controller('ConfirmCtrl', ['$scope', '$timeout', '$location', 'APIConfirm', '$rootScope', 'UI', '$cookies', 'APIActivity'].append(function(self, $timeout, $location, APIConfirm, root, UI, $cookies, APIActivity) {
  var params;
  self.formProccess = false;
  self.formSuccess = false;
  params = $location.search();
  if (params && angular.isDefined(params.confirm)) {
    self.formProccess = true;
    return APIConfirm.get({
      hash: params.confirm
    }, function(data) {
      var ref, reg_ref;
      // sync offline activities
      reg_ref = $cookies.get('reg_ref') || null;
      if ((reg_ref != null) && reg_ref.split('--')[1] === ((ref = data.referred_supplier) != null ? ref.referrer_hash : void 0)) {
        $cookies.remove('reg_ref');
        (new APIActivity()).$save({
          shortcode: reg_ref,
          auth_ticket: data.auth_ticket,
          ref_id: data.referred_supplier.id
        }, function(data) {
          return console.log("sync activity success");
        }, function(response) {
          return console.log(response.data.error);
        });
      }
      self.formProccess = false;
      return self.formSuccess = true;
    /*
    usercookie =
      shortcode:    data.shortcode
      auth_ticket:  data.auth_ticket
      role:         data.role || null
      guest:        false
    $cookies.putObject 'user', usercookie

    root.$emit 'proveSession', true
    */
    }, function(response) {
      self.formProccess = false;
      self.formSuccess = false;
      return UI.notify(false, response.data.error);
    });
  } else {
    return $location.path("/");
  }
}));
